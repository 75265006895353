
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import modal from './Modal.vue'

const Error = defineComponent({
    components: { modal },
    setup() {
        const store = useStore()
        const error = computed( () => store.state.lastError )
        const showError = () => error.value !== null && error.value.length > 0
        const clearError = () => store.dispatch('clearError')

        return {
            error,
            showError,
            clearError
        }
    }
})

export default Error
