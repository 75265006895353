export default class Constants {
    public static readonly basePrice     = 1.55;
    public static readonly deliveryArkh  = 100;
    public static readonly paperMarkup   = 0.3;
    public static readonly oneCut        = 1.2;

    public static readonly priceRoundingBase      = 1;
    public static readonly timeRoundingBase       = 0.5;
    public static readonly oneFoldPrice           = 0.2;
    public static readonly oneSideLaminationPrice = 5;
    public static readonly oneCreasingPrice       = 0.6;
    
    public static readonly maxX = 437;
    public static readonly maxY = 308;

    public static readonly oneCalendarAssembly   = 10;
    public static readonly oneTearOffQuarter     = 20;
    public static readonly oneQuarterCalendCompo = 6.6;

    public static readonly oneWireOPrice    = 1.6;
    public static readonly oneRoundingPrice = 0.15;

    public static readonly oneBindPrice: Record<string, any> = {
        glue: 20,
        stitch: 5,
        wireo: 15
    };

    public static readonly oneRiegelPrice          = 10;
    public static readonly onePersonalisationPrice = 1;
    public static readonly oneOpaquePrice          = 2.5;

    public static readonly markup = 0.18;

    public constructor() {
        // empty, it's just a constants class
    }
}