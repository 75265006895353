export default class EnumElement {
    public readonly value: number;
    public readonly name: string;
    public readonly description: string;

    public constructor(value: number, name: string, description: string) {
        this.value = value;
        this.name = name;
        this.description = description;
    }
}