import { createStore } from 'vuex'
import { session } from './session.module'

import Product from '@/model/Product'
import Order, { addProductsToOrder } from '@/model/Order'

import { list } from '@/api/OrderActions'
import { createProduct, getTypes } from '@/api/ProductActions'

export interface State {
    currentProduct: Product | null,
    currentOrder: Order,
    orders: Order[],
    lastError: string | null,
} 

export const actions: any = {
    
    setActiveProductType({ commit }, type = getTypes()[0].type) {
        commit('SET_ACTIVE', createProduct(type) )
    },

    setActiveProduct({ commit }, product: Product) {
        commit('SET_ACTIVE', product)
    },

    getOrders({ commit }) {
        list().then((orders: any) => commit('SET_ORDERS', orders))
            // eslint-disable-next-line 
            .catch((err: any) => console.error(err))
    },

    addProductToOrder({ commit, state }, ) {
        commit('UPDATE_CART_ORDER',
            addProductsToOrder(state.currentOrder, [state.activeProduct]))
    },

    // eslint-disable-next-line 
    setActiveProductFromJson({ commit, state }, json: Record<string, number | string>) {

    },

    // eslint-disable-next-line 
    saveOrderForSession({ commit, state }) {

    },

    clearError({ commit }): void {
        commit('CLEAR_ERROR')
    }
}

const store = createStore({
    modules: {
        session: session,
    },
    
    state: {
        allProducts: [],
        currentProduct: null,
        currentOrder: Order.EMPTY,
        orders: [],
        lastError: null
    } as State,

    mutations: {
        SET_ACTIVE(state: any, product: Product) {
            state.currentProduct = product
            state.currentProduct.calculate()
        },
        SET_ORDERS(state: any, orders: any) {
            state.orders = orders
        },
        SET_ERROR(state: any, errorMessage: string) {
            state.lastError = errorMessage
        },
        CLEAR_ERROR(state: any) {
            state.lastError = null
        }
    },
    actions: actions,
});

export default store