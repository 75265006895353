import ProductUnit from './ProductUnit';
import PaperType from './unit/PaperType';
import Copies from './unit/Copies';
import PaperElement from './unit/elements/PaperElement';
import UiUnit from './UiUnit';
import cyrb53 from '@/util/cyrb53';
import { ProductType } from './ProductFactory';

export type ProductUiUnit = ProductUnit<any> & UiUnit;

export default class Product implements ProductType {
    public readonly id: string;
    public readonly title: string;
    public readonly type: string;
    private units: ProductUnit<any>[] = [];
    private unitsMap: Map<string, ProductUnit<any>> = new Map();
    private price = 0;
    private overhead = 0;
    private time = 0;
    private papers: PaperElement[] = [];

    public constructor(id: string, title: string) {
        this.id = id;
        this.title = title;
        this.type = id;
    }

    public addUnit(unit: ProductUnit<any>): void {
        unit.setProduct(this);
        this.units.push(unit);
        this.unitsMap.set(unit.id, unit);
    }

    public getUiUnits(): ProductUiUnit[] {
        return this.units.filter(Product.isNotPaperType).map(p => p as ProductUiUnit);
    }

    public getPaperUnits(): ProductUnit<any>[] {
        return this.units.filter(Product.isPaperType);
    }

    public getUnit(id: string): ProductUnit<any> {
        if (!this.unitsMap.has(id)) {
            throw "No such product unit: " + id;
        }
        return this.unitsMap.get(id) as ProductUnit<any>;
    }

    public calculate(): number {
        this.price = 0;
        this.price = this.units.reduce( 
            (price: number, unit: ProductUnit<any> ) => price + unit.calculate(), 
            0
        );

        return this.price; 
    }

    public getPrice(): number {
        return this.price;
    }

    public getQty(): number {
        if (this.getUnit("qty") instanceof Copies)
            return (this.getUnit("qty") as Copies).calculate();
    
        return 0;
    }

    public setPapers(papers: PaperElement[]): void {
        this.papers = papers;
    }

    public getPapers(): PaperElement[] {
        return this.papers;
    }

    public getOverhead(): number {
        return this.overhead;
    }

    public toJSON(): Record<string, string|number> {
        const json: Record<string, string|number> = {};
        this.getUiUnits()
            .map( u => u as ProductUnit<any> ) // Can it be hust "number" here?
            .forEach( unit => json[unit.id] = unit.getValue() );

        this.getPaperUnits()
            .map( u => u as PaperType )
            .forEach( paper => json[paper.id] = paper.getValue() );

        json.id = this.id;
        json.title = this.title;
        
        json.description = this.getDescription();
        json.hashCode = this.hashCode();
        
        return json;
    }

    public fromJSON(state: Record<string, number | string>): void {
        this.units.filter(Product.isUiUint)
            .filter( u => typeof(state[u.id]) !== "undefined" )
            .map( u => u as ProductUnit<any> )
            .map( u => u instanceof PaperType 
                ? u.setValue(state[u.id] as string) 
                : u.setValue(state[u.id] as number) );
    }

    public hashCode(): number {
        return cyrb53(this.id) + this.units.map(u => u.hashCode() ).reduce( (acc, h) => acc + h, 0);
    }

    public getDescription(): string {
        return this.units
            .filter( u => u.description() != "" )
            .map( u => u.description() )
            .join(", ");
    }

    private static isUiUint(unit: ProductUnit<any>): boolean {
        return (unit as unknown as UiUnit).uiUnitType !== undefined
    }
    
    private static isNotPaperType = (unit: ProductUnit<any>) => 
        Product.isUiUint(unit) && !Product.isPaperType(unit);

    private static isPaperType = (unit: ProductUnit<any>) =>
        Product.isUiUint(unit) && unit instanceof PaperType;

}