import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "col-label" }
const _hoisted_3 = { class: "amount" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.unit.id,
    class: "col-select form-element"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.unit.title), 1)
    ]),
    _withDirectives(_createElementVNode("input", {
      id: "sheet_qty",
      type: "text",
      maxlength: "5",
      class: "styler",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentValue) = $event))
    }, null, 512), [
      [
        _vModelText,
        _ctx.currentValue,
        void 0,
        { number: true }
      ]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", {
        href: "javascript://",
        class: "btn-m",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setValue(_ctx.unit.getPrevious())))
      }),
      _createElementVNode("a", {
        href: "javascript://",
        class: "btn-p",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setValue(_ctx.unit.getNext())))
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unit.presets, (p) => {
      return (_openBlock(), _createElementBlock("a", {
        key: p,
        href: "javascript://",
        class: "amount-change",
        onClick: ($event: any) => (_ctx.setValue(p))
      }, _toDisplayString(p), 9, _hoisted_4))
    }), 128))
  ], 8, _hoisted_1))
}