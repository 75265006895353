import Product from './Product';
import PaperSize from './unit/PaperSize';
import Copies from './unit/Copies';
import Colors from './unit/Colors';
import PaperType from './unit/PaperType';
import Lamination from './unit/Lamination';
import Delivery from './unit/Delivery';
import { FIndigo, FPrint, FFixedValue, FDieCut } from './unit/Formulas';
import PaperElement from './unit/elements/PaperElement';

export type ProductType = {
    readonly type: string;
    readonly title: string;
}

class ProductComponents {
    public readonly id: string;
    public readonly unit: string;
    public readonly title: string  | undefined;
    public readonly value: number  | string;
    public readonly value2: number | undefined;
    public readonly presets: any[] | undefined;

    public constructor(id: string, unit: string, title: string | undefined, value: number | string, value2: number | undefined, presets: any[] | undefined) {
        this.id = id;
        this.unit = unit;
        this.value = value;
        this.value2 = value2;
        this.presets = presets;
        this.title = title;
    }
}

let instance: ProductFactory
export class ProductFactory {
    private readonly types: ProductType[];
    private readonly defaultComponents: Map<string, ProductComponents[]>;
    private readonly papers: PaperElement[];

    constructor(typesJson: Record<string, any>[], componentsJson: Record<string, any>[], papers: PaperElement[]) {
        this.types = this.createProductTypes(typesJson);
        this.defaultComponents = this.createProductComponents(componentsJson);
        this.papers = papers;
    }

    public createProduct(type: string, state?: Record<string, string | number>): Product {
        const pType = this.getType(type)

        if (!pType)
            throw "Incorrect type: " + type;

        const product = new Product(pType.type, pType.title);

        const addComps = this.getComponents(pType) || [];

        addComps.forEach( comp => {
            const compValue = (typeof(state) !== "undefined" && state[comp.id]) ? state[comp.id] : comp.value;

            switch (comp.unit) {
                case "FPrint": product.addUnit( new FPrint(comp.id, compValue as number, comp.value2 as number) ); break;
                case "FIndigo": product.addUnit( new FIndigo(comp.id) ); break;
                case "FFixedValue": product.addUnit( new FFixedValue(comp.id, comp.title as string, compValue as number) ); break;
                case "FDieCut": product.addUnit( new FDieCut(comp.id) ); break;
                case "PaperSize": product.addUnit( new PaperSize(comp.id, compValue as number) ); break;
                case "Copies": product.addUnit( new Copies(comp.id, compValue as number) ); break;
                case "Colors": product.addUnit( new Colors(comp.id, compValue as number, comp.title as string) ); break;
                case "PaperType": product.addUnit( new PaperType( comp.id, compValue as string, comp.title as string) ); break;
                case "Lamination": product.addUnit( new Lamination(comp.id, compValue as number, comp.title as string) ); break;
                case "Delivery": product.addUnit( new Delivery(comp.id, compValue as number) ); break;
                default: throw new Error("Unexpected component type/unit: " + comp.unit);
            }
        } );

        product.setPapers(this.papers);

        return product;
    }

    public getType = (type: string) => this.types.find( t => t.type === type );

    private createProductTypes(types: Record<string, string>[]): ProductType[] {
        const arr: ProductType[] = [];
        types.forEach( (entry) =>
            arr.push( { type: entry.type, title: entry.title } ) 
        );

        return arr;
    }

    private createProductComponents(components: Record<string, any>[]): Map<string, ProductComponents[]> {
        const compMap = new Map();
        components.forEach( (entry: any ) => {
            const compList: ProductComponents[] = [];
            entry.components.forEach( 
                (comp: { id: string; unit: string; title: string | undefined; value: number | string; value2?: number; presets?: any[] } ) => 
                    compList.push( new ProductComponents(comp.id, comp.unit, comp.title, comp.value, comp.value2, comp.presets) )
            );

            compMap.set(entry.type, compList) ;
        });

        return compMap;
    }

    private getComponents(type: ProductType): ProductComponents[]{
        return this.defaultComponents.get(type.type) || [];
    }
}

export default function getFactory(typesJson: Record<string, any>[], componentsJson: Record<string, any>[], papers: PaperElement[]): ProductFactory {
    if (instance)
        return instance

    instance = new ProductFactory(typesJson, componentsJson, papers)
    return instance
}


