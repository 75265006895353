
import { computed, defineComponent, PropType, ref } from 'vue';

import modal from './Modal.vue';
import PaperElement from '../model/unit/elements/PaperElement';
import PaperType from '@/model/unit/PaperType';

export default defineComponent({
  
  components: { modal },
 
  emits: [ 'set-component-value' ],

  props: {
    paper: { type: Object as PropType<PaperType>, required: true },
    papersList: { type: Array as PropType<PaperElement[]>, required: true }
  },

  setup(props, { emit }) {
    const isPaperListOpen = ref(false);
    const openPaperList = () => isPaperListOpen.value = true
    const closePaperList = () => isPaperListOpen.value = false
    const defaultPaper = computed( () => props.papersList.find( p => p.id == props.paper.getValue() ) )
    const paperImageUrl = (paper: PaperElement) => { return  { 'background-image' : "url('http://liteprint.me/i/paper_big/" + paper.id + ".jpg')" } }
    const paperImageCurlesUrl = (paper: PaperElement) => { return { 'background-image': "url('http://liteprint.me/i/paper_big/" + paper.id + "-curl.jpg')" } }
    const selectPaper = (newPaper: PaperElement) => {
      props.paper.setValue(newPaper.id);
      emit('set-component-value', props.paper.id, newPaper);
      closePaperList();
    }    

    return {
      isPaperListOpen,
      openPaperList,
      closePaperList,
      defaultPaper,
      paperImageUrl,
      paperImageCurlesUrl,
      selectPaper,
    }

  },

  created () {
      this.$emit('set-component-value', this.paper.id, this.paper.getValue())
  }
})
