import papersJson from '@/papers.json'
import productTypesJson from '@/productTypes.json'
import productComponents from '@/productComponents.json'
import getFactory, { ProductType } from '@/model/ProductFactory';
import PaperElement from '@/model/unit/elements/PaperElement';
import PaperFactory from '@/model/PaperFactory';
import Product from '@/model/Product';

const productTypes = ['sheet', 'brochure'];
const papers: PaperElement[] = new PaperFactory(papersJson).build();
const productFactory = getFactory(productTypesJson, productComponents, papers);

export function createProducts(): Product[] 
{
    return productTypes.map(t => productFactory.createProduct(t))
}

export const getTypes = () => productTypes.map(t => productFactory.getType(t))
                                          .filter((pt): pt is ProductType => !!pt)

export const createProduct = (type: string) => productFactory.createProduct(type)