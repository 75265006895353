
import { computed, defineComponent, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex';
import { getTypes } from '@/api/ProductActions';

// Domain model
import Product        from '@/model/Product';
import Order          from '@/model/Order';

// New UI components
import PaperUnit      from '@/components/PaperUnit.vue';
import IntValuesUnit  from '@/components/IntValuesUnit.vue';
import EnumValuesUnit from '@/components/EnumValuesUnit.vue';
import DropdownUnit   from '@/components/DropdownUnit.vue';
import CheckBoxUnit   from '@/components/CheckBoxUnit.vue';
import PriceAndInfo   from '@/components/PriceAndInfo.vue';
import Cart           from '@/components/Cart.vue';

const Calculator = defineComponent({
    components: {
        PriceAndInfo,
        Cart,
        PaperUnit,
        IntValuesUnit,
        EnumValuesUnit,
        DropdownUnit,
        CheckBoxUnit,
    },

    setup() {
        const route = useRoute()
        const store = useStore()
        const productTypes = getTypes()
        const activeProduct = computed(() => store.state.currentProduct as Product)

        watch(
            () => route.params,
            async routeParams => {
                if (typeof routeParams.hashcode === 'undefined')
                    return
                
                const hashCodeParam = routeParams.hashcode as string
                const orderIdparam = routeParams.orderid as string
                const hashCode = parseInt(hashCodeParam)

                const product = orderIdparam !== undefined
                    ? loadFromOrder(parseInt(orderIdparam), hashCode)
                    : loadProduct(hashCode)
                
                if (product)
                    store.dispatch('setActiveProduct', product)
            }   
        )

        function loadProduct(hashCode: number): Product | null {
            const products = store.state.session.products as Map<number, Product>
            const cp = products.get(hashCode);

            if (typeof cp === "undefined") {
                // eslint-disable-next-line 
                console.log("product not found: " + hashCode)
                return null;
            }

            return cp 
        }

        function loadFromOrder(id: number, hashCode: number): Product | null {
            const order = store.state.orders.find( (o: Order) => o.id === id) as Order

            if (typeof order === "undefined") {
                // eslint-disable-next-line 
                console.log("order not found: " + id)
                return null;
            }

            const op = order.products.find( (p: Product) => p.hashCode() === hashCode )

            if (typeof op === "undefined") {
                // eslint-disable-next-line 
                console.log("product not found: " + hashCode)
                return null;
            }

            const product = store.state.products.find( (p: Product) => p.id == op.id );

            return product
        }

        return {
            store,
            productTypes,
            activeProduct
        }
    },
    
    mounted () {
        fixHeight();
    },

    methods: {
        setActiveProductType(type: string): void {
            this.store.dispatch('setActiveProductType', type)
            this.recalculate()
        },

        recalculate(): void {
            this.activeProduct.calculate();
        }
    },
})

export default Calculator

function fixHeight(): void {
    // TODO: найти правильный способ для определения высоты
    const compsElem = document.getElementById('components')
    const contentElem = document.getElementById('content')
    if (compsElem !== null && contentElem !== null) {
        const compsHeight = compsElem.clientHeight + 275
        contentElem.style.height = compsHeight + "px"
    }
}

