
import { computed, defineComponent, ref } from "vue";
import { storeOrder } from "@/api/OrderActions";

import Product from "@/model/Product";
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();
        const products = ref(
            store.state.session.products as Map<number, Product>
        );
        const isUserLoggedIn = computed(
            () =>
                store.state.session.session != null &&
                store.state.session.session.sessionId != null
        );
        const storeOrder = () => {
            if (!isUserLoggedIn.value) return;
            // storeOrder(store.state.session.session, store.state.order)
        };

        return {
            products,
            isUserLoggedIn,
            storeOrder,
        };
    },
});
