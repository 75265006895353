import Product from "@/model/Product";

/* eslint-disable */
export default class Order {
    public static readonly EMPTY = new Order(0, 0, 0, []);
    
    public constructor(readonly id: number, readonly created: number, 
        readonly clientId: number, readonly products: Product[]) {
        // default constructor
    }
}

export function addProductsToOrder(order: Order, products: Product[]): Readonly<Order> 
{
    return new Order(order.id, order.created, order.clientId, [...order.products, ...products] );
}

export function updateOrder(order: Order, updateWith: Partial<Order>): Readonly<Order> {
    return { ...order, ...updateWith }
}