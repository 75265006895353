
import dropdown from 'vue-dropdowns';
import { computed, defineComponent, PropType } from 'vue';

import EnumValue from '../model/unit/types/EnumValue';
import EnumElement from '../model/unit/elements/EnumElement';
import AbstractProductUnit from '@/model/AbstractProductUnit';

export default defineComponent({
  components: { dropdown },
  props: {
    unit: {
      type: Object as PropType<EnumValue & AbstractProductUnit<number>>,
      required: true
    }
  },

  emits: ['set-component-value'],

  setup(props, { emit }) {
    const updateSelectedProduct = (element: EnumElement) => {
      props.unit.setValue(element.value)
      emit('set-component-value', props.unit.id, element.value);
    }
    const selected = computed( () => props.unit.presets.find(p => p.value == props.unit.getValue()) || props.unit.presets[0])
  
    return {
      updateSelectedProduct,
      selected
    }
  }
})
