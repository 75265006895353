import cyrb53 from '@/util/cyrb53';
import Product from './Product';
import ProductUnit from './ProductUnit';

export default abstract class AbstractProductUnit<T> implements ProductUnit<T> {
    public readonly id: string;
    public readonly title: string;
    protected value: T | 0 = 0;
    protected product: Product = new Product("empty", "empty");

    public constructor(id: string, title: string) {
        this.id = id;
        this.title = title;
    }

    public setProduct(ref: Product): void {
        this.product = ref;
    }

    public calculate(): number {
        return typeof this.value === "number" ? this.value as number : 0;
    }

    public description(): string {
        return "";
    }

    public hashCode(): number {
        return typeof this.value == "string" 
            ? cyrb53(this.value)
            : typeof this.value == "number" ? Math.imul(31, this.value) : 0
    }

    public getValue(): T | 0 {
        return this.value;
    }

    public setValue(value: T) {
        this.value = value; 
    }
}
