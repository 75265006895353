
import { computed, defineComponent, PropType } from "vue";

import AbstractProductUnit from "@/model/AbstractProductUnit";
import SwitchValue from "../model/unit/types/SwitchValue";

export default defineComponent({
    props: {
        unit: {
			type: Object as PropType<SwitchValue & AbstractProductUnit<number>>,
			required: true
		}
    },

    emits: ["set-component-value"],

    setup(props, { emit }) {
        const isChecked = computed(() =>
            props.unit.isChecked() ? "checked" : ""
        );
        const switchValue = () => {
            props.unit.switchValue();
            emit("set-component-value", props.unit.id, props.unit.getValue());
        };

        return {
            isChecked,
            switchValue,
        };
    },
});
