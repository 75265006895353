import PaperType from './PaperType';
import AbstractProductUnit from '../AbstractProductUnit';
import Constants from '../Constants';

export class FDieCut extends AbstractProductUnit<number> {
    private readonly min: number;
    private readonly div: number;

    public constructor(id: string, min?: number, div?: number) {
        super(id, "Резка");
        this.min = typeof(min) === 'undefined' ? 3 : min;
        this.div = div || 2 
    }

    public calculate(): number {
        return Constants.oneCut * Math.ceil(this.min + this.product.getUnit("size").calculate()/this.div);
    }
}

export class FFixedValue extends AbstractProductUnit<number> {
    private readonly fixedValue: number;
    
    public constructor(id: string, title: string, value: number) {
        super(id, title);
        this.fixedValue = value;
    }

    public calculate(): number {
        return this.fixedValue;
    }

}

export class FIndigo extends AbstractProductUnit<number> {
    private readonly qtyMultiplier: number;

    public constructor(id: string, multipl?: number) {
        super(id, "Индиго");
        this.qtyMultiplier = multipl || 1; 
    }

    public calculate(): number {
        return Constants.basePrice
                * this.product.getUnit("color").calculate()
                * ((this.product.getUnit("paper") as PaperType).overhead
                    + Math.ceil(
                        this.qtyMultiplier * this.product.getUnit("qty").calculate() / this.product.getUnit("size").calculate()
                    ))
    }
}

export class FPrint extends AbstractProductUnit<number> {
    private readonly lowLimit: number;
    private readonly upperLimit: number;

    public constructor(id: string, lowLim: number, upLim: number) {
        super(id, "Печать");
        this.lowLimit = lowLim;
        this.upperLimit = upLim;
    }

    public calculate(): number {
        return this.product.getPrice()
                * this.float1000(
                    this.product.getUnit("qty").calculate() / this.product.getUnit("size").calculate(),
                    this.lowLimit, this.upperLimit
                );
    }

    private float1000(qty: number, lowK: number, highK: number): number {
        if (highK == lowK || isNaN(qty) || qty < 1) return highK;
        if (qty >= 1000) return lowK - 0.5;
        let k = 0.70383349 + 2.26120533 * Math.pow(qty/500+1, -16.5383228); //*(highK-lowK) + lowK;
        if (k > highK) k = highK;
        if (k < lowK) k = lowK;
        return k;
    }
}
