
import { computed, defineComponent } from "vue";

import { useStore } from "vuex";
import Order from "@/model/Order";

const Orders = defineComponent({
    setup() {
        const store = useStore();
        const ordersList = computed(() => store.state.orders as Order[]);

        return {
            store,
            ordersList,
        };
    },

    beforeMount() {
        this.store.dispatch("getOrders");
    }

});

export default Orders;
