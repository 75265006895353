import EnumValue from './types/EnumValue';
import EnumElement from './elements/EnumElement';
import AbstractProductUnit from '../AbstractProductUnit';

export default class PaperSize extends AbstractProductUnit<number> implements EnumValue {
    public readonly uiUnitType: string = 'EnumValuesUnit';
    public readonly enumType: string = 'rect';
    public readonly presets: EnumElement[];

    public constructor(id: string, defaultValue: number, presets?: EnumElement[]) {
        super(id, "Формат");
        this.value = defaultValue;
        this.presets = (presets != null)
            ? presets
            : [
                new EnumElement(1, 'A3', ''),
                new EnumElement(2, 'A4', ''),
                new EnumElement(3, 'A5', ''),
                new EnumElement(4, 'A6', ''),
            ];
    }

    public setValue(newValue: number): void {
        this.value = newValue;
    }

    public description(): string {
        return this.title + "&nbsp;" + (this.presets.find( p => p.value == this.value ) as EnumElement).name;
    }

}
