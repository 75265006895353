import PaperElement from './unit/elements/PaperElement';

export default class PaperFactory {
    private readonly sourceJson: Record<string, any>;
    public constructor(json: Record<string, any>) {
        this.sourceJson = json;
    }

    public build(): PaperElement[] {
        const papers: PaperElement[] = [];
        const sourceJson: Record<string, any> = this.sourceJson;
        Object.entries(sourceJson).forEach(function(entry: [string, any]) {
            papers.push(
                new PaperElement(entry[0], entry[1].name, "", entry[1].price, entry[1].sides, entry[1].size, entry[1].group)
            )
        });
        
        return papers;
    }
}