import axios, { Axios } from 'axios'

export default class axiosWrapper {
    private readonly sessionId: string | null;
    private readonly axios: Axios
    private readonly defaults = {
                baseURL: process.env.VUE_APP_API_URL,
                timeout:  5000
    }

    private static readonly DEFAULT = new axiosWrapper().axios

    private constructor() {
        this.sessionId = null //localStorage.getItem('Session-Id')
        const config = this.sessionId != null
            ? { ...this.defaults, ...{ headers: { 'Session-Id' : this.sessionId } } }
            : this.defaults
    
        this.axios = axios.create(config)
    }

    public static getDefault(): Axios {
        return this.DEFAULT
    }

    public static withSession(): Axios {
        return new axiosWrapper().axios
    }
}