
import { defineComponent, PropType, watch } from 'vue';

import AbstractProductUnit from '@/model/AbstractProductUnit';
import EnumValuesSize from './EnumValuesSize.vue';
import EnumValue from '../model/unit/types/EnumValue';


export default defineComponent({
  components: { EnumValuesSize },

  emits: [ 'set-component-value' ],

  props: {
    unit: {
      type: Object as PropType<EnumValue & AbstractProductUnit<number>>,
      required: true,
    }
  },

  setup(props, { emit }) {
    watch(
      () => props.unit.getValue(),
      (newValue) => emit('set-component-value', props.unit.id, newValue)
    )
  }
})

