import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc90e486"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!
  const _component_ErrorModal = _resolveComponent("ErrorModal")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createElementVNode("a", {
      href: "javascript://",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchLoginForm()))
    }, "Login"),
    (_ctx.isLoginFormOpen)
      ? (_openBlock(), _createBlock(_component_modal, { key: 0 }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
              return (_openBlock(), _createElementBlock("button", {
                key: tab,
                class: _normalizeClass(['tab-button', { active: _ctx.currentTab === tab }]),
                onClick: ($event: any) => (_ctx.currentTab = tab)
              }, _toDisplayString(tab), 11, _hoisted_1))
            }), 128)),
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentTabComponent), {
              onCompleteUserAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.switchLoginForm())),
              class: "tab"
            }))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ErrorModal)
  ]))
}