import IntValue from './types/IntValue';
import AbstractProductUnit from '../AbstractProductUnit';

export default class Copies extends AbstractProductUnit<number> implements IntValue {
    public readonly uiUnitType = 'IntValuesUnit';
    public readonly units: string = 'экз.';
    private readonly minVal: number = 1;
    private readonly maxVal: number = 9999;
    private readonly increment: number = 10;
    public readonly presets: number[];

    public constructor(id: string, defaultValue: number, presets?: number[]) {
        super(id, "Тираж");
        this.value = defaultValue;
        this.presets = (presets != null) ? presets : [10, 50, 100, 200, 300, 500];
    }

    public setValue(newValue: number): void {
        if (newValue <= this.maxVal && newValue >= this.minVal) {
            this.value = newValue;
            return;
        }

        throw new Error('newValue is out of range: ' + newValue);
    }

    public getNext(): number {
        return (this.value + this.increment >= this.maxVal) ? this.maxVal : this.value + this.increment
    }

    public getPrevious(): number {
        return (this.value - this.increment <= this.minVal) ? this.minVal : this.value - this.increment
    }

    public description(): string {
        return this.value.toString() + "&nbsp;" + this.units;
    }
}
