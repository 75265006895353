export default class PaperElement {
    public readonly id: string;
    public readonly title: string;
    public readonly density: string;
    public readonly price: number;
    public readonly sides: number;
    public readonly size: string;
    public readonly group: string;

    public constructor(id: string, title: string, density: string, price: number, sides: number, size: string, group: string) {
        this.id = id;
        this.title = title;
        this.density = density;
        this.price = price;
        this.sides = sides;
        this.size = size;
        this.group = group;
    }
}