
import { computed, defineComponent, onBeforeMount } from "vue";
import { useStore } from "vuex";

// General components
import LoginOrRegister from "@/components/LoginOrRegister.vue";

const App = defineComponent({
    components: { LoginOrRegister },

    setup() {
        const store = useStore();
        const isSession = computed(
            () =>
                store.state.session.session != null &&
                store.state.session.session.sessionId != null
        );

        onBeforeMount(() => {
            store.dispatch("setActiveProductType");
        });

        return {
            store,
            isSession,
        };
    },

    goBack() {
        window.history.length > 1
            ? this.$router.go(-1)
            : this.$router.push("/");
    },
});

export default App;
