import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "col-label" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnumValuesSize = _resolveComponent("EnumValuesSize")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.unit.id
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        innerHTML: _ctx.unit.title
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass('col-select ' + _ctx.unit.enumType)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unit.presets, (elem) => {
        return (_openBlock(), _createBlock(_component_EnumValuesSize, {
          key: elem.value,
          element: elem,
          "enum-unit": _ctx.unit
        }, null, 8, ["element", "enum-unit"]))
      }), 128))
    ], 2)
  ], 8, _hoisted_1))
}