
import { LoginRequest } from "@/api/types/AuthRequest";
import { ErrorMessage, Field, Form } from "vee-validate";
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    
    components: { Form, Field, ErrorMessage },

    emits: ['complete-user-action'],

    setup(props, { emit }) {
        const store = useStore()
        const handleLogin = (values: any) =>  {
            emit('complete-user-action') // Close modal
            store.dispatch("session/login", values) // Try to login 
        }   

        return {
            handleLogin,
        }
    }
})
