import AbstractProductUnit from '../AbstractProductUnit';
import UiUnit from '../UiUnit'
import PaperElement from './elements/PaperElement';

export default class PaperType extends AbstractProductUnit<string> implements UiUnit {
    public readonly uiUnitType: string = 'PaperModalSelect';
    public readonly overhead: number;

    public constructor(id: string, defaultValue: string, title?: string, overhead?: number) {
        super(id, title || "Страницы");
        this.value = defaultValue;

        if (typeof(overhead) !== "undefined")
            this.overhead = overhead;
        else
            this.overhead = 0;
    }

    public calculate(): number {
        return this.getPaperElement().price
            * (this.product.getOverhead() 
                + Math.ceil( this.product.getUnit("qty").calculate() 
                            / this.product.getUnit("size").calculate() )
            );
    }

    public description(): string {
        return this.getPaperElement().title;
    }

    public hashCode(): number {
        if (this.value === 0)
            return 0;
    
        return this.value.split('').reduce((acc, c) => Math.imul(31, acc) + c.charCodeAt(0) | 0, 0 ) 
            + this.id.split('').reduce((acc, c) => Math.imul(31, acc) + c.charCodeAt(0) | 0, 0 )
    }

    private getPaperElement(): PaperElement {
        const paperEl: PaperElement | undefined = this.product.getPapers().find( p => p.id === this.value );

        if (typeof(paperEl) === "undefined")
            throw new Error("Cannot fine papper with id: " + this.value);

        return paperEl;
    }
}