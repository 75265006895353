
import { computed, defineComponent, PropType, watch } from "vue";

import AbstractProductUnit from "@/model/AbstractProductUnit";
import IntValue from "../model/unit/types/IntValue";

export default defineComponent({
    emits: ["set-component-value"],

    props: {
        unit: {
            type: Object as PropType<IntValue & AbstractProductUnit<number>>,
            required: true,
        },
    },

    setup(props, { emit }) {
        const currentValue = computed(() => props.unit.getValue());
        const setValue = (p: number) => {
            props.unit.setValue(p);
            emit("set-component-value", props.unit.id, p);
        };

        watch(
            () => currentValue.value,
            (newVal) => {
                props.unit.setValue(newVal);
                emit("set-component-value", props.unit.id, newVal);
            }
        );

        return {
            currentValue,
            setValue,
        };
    },
});
