import EnumValue from './types/EnumValue';
import EnumElement from './elements/EnumElement';
import AbstractProductUnit from '../AbstractProductUnit';
import Constants from '../Constants';

export default class Lamination extends AbstractProductUnit<number> implements EnumValue {
    public readonly uiUnitType: string = 'DropdownUnit';
    public readonly enumType: string = 'rect rect-l';
    public readonly presets: EnumElement[];

    public constructor(id: string, defaultValue: number, title?: string, presets?: EnumElement[]) {
        super(id, title || "Ламинация");
        this.value = defaultValue;
        this.presets = (presets != null)
            ? presets
            : [
                new EnumElement(0, 'нет',            ''),
                new EnumElement(1, 'односторонняя',  ''),
                new EnumElement(2, 'двусторонняя',   ''),
            ];
    }

    public setValue(newValue: number): void {
        this.value = newValue;
    }

    public description(): string {
        const lamElem: EnumElement = this.presets.find( e => e.value === this.value ) as EnumElement;

        return this.value === 0 
            ? "" 
            : lamElem.name.charAt(0).toLocaleUpperCase() + lamElem.name.slice(1)
                + "&nbsp;"
                    + this.title.toLocaleLowerCase();
    }

    public calculate(): number {
        return Constants.oneSideLaminationPrice
            * this.value // * a.paper.overhead
            * ( Math.ceil(this.product.getUnit('qty').calculate()
                / this.product.getUnit('size').calculate() ) );
    }
}