import axiosWrapper from "@/api/AxiosWrapper";
import { LoginRequest, RegisterRequest } from "@/api/types/AuthRequest"
import { SessionAndClient } from "@/api/types/AuthResponse";
import SessionImpl, { Session } from "@/model/auth/Session"
import Product from "@/model/Product";

// TODO: интерфейс для ошибки, см: https://axios-http.com/docs/handling_errors

export interface SessionState {
    session: Session | null,
    products: Map<number, Product>,
}

export const session = {
    namespaced: true,

    state: () => ({
        session: null,
        products: new Map<number, Product>() 
    } as SessionState),

    mutations: {
        SET_SESSION(state: SessionState, session: Session) {
            state.session = session
        },
        CLEAR_SESSION(state: any) {
            state.session = null;
        },
        ADD_PRODUCT(state: SessionState, product: Product) {
            const hashCode = product.hashCode() + Date.now() 
            state.products.set(hashCode, product)
        },
        REMOVE_PRODUCT(state: SessionState, hashCode: number) {
            state.products.delete(hashCode)
        }
    },

    actions: {
        login({ commit }, data: LoginRequest): void {
            axiosWrapper.getDefault().post(
                    process.env.VUE_APP_POST_LOGIN_URL_PATH,
                    data
                )
                .then((response) => {
                    commit('SET_SESSION', sessionFromResponse(response))
                })
                .catch((err) => {
                    const error: string = err.response ? err.response.data : err
                    commit('SET_ERROR', error, { root: true })
                })
        },

        register({ commit }, data: RegisterRequest): void {
            axiosWrapper.getDefault().post(
                    process.env.VUE_APP_POST_REGISTER_URL_PATH,
                    data
                )
                .then((response) => {
                    commit('SET_SESSION', sessionFromResponse(response))
                })
                .catch((err) => {
                    const error: string = err.response ? err.response.data : err
                    commit('SET_ERROR', error, { root: true })
                })
        },

        addProduct({ commit }, product: Product): void {
            commit('ADD_PRODUCT', product)
        },

        removeProduct({ commit }, hashCode: number): void {
            commit('REMOVE_PRODUCT', hashCode)
        }
    }
}

function sessionFromResponse(response): Session {
    const respData: SessionAndClient = response.data
    return new SessionImpl(
        respData.sessionId,
        respData.user
    )
}