import EnumValue from './types/EnumValue';
import EnumElement from './elements/EnumElement';
import AbstractProductUnit from '../AbstractProductUnit';

export default class Colors extends AbstractProductUnit<number> implements EnumValue {
    public readonly uiUnitType: string = 'EnumValuesUnit';
    public readonly enumType: string = 'rect rect-l';
    public readonly presets: EnumElement[];

    public constructor(id: string, defaultValue: number, title?: string, presets?: EnumElement[]) {
        super(id, title || "Печать");
        this.value = defaultValue;
        this.presets = (presets != null)
            ? presets
            : [
                new EnumElement(4, '4+0',        'Односторонняя цветная печать'),
                new EnumElement(5, '4+1',        'Двусторонняя печать, лицо – цвет, оборот ч/б'),
                new EnumElement(8, '4+4',        'Двусторонняя цветная печать'),
                new EnumElement(2, '1+1',        'Двусторонняя ч/б печать'),
                new EnumElement(1, '1+0',        'Односторонняя ч/б печать'),
                new EnumElement(0, 'без печати', 'Без печати'),
            ];
    }

    public setValue(newValue: number): void {
        this.value = newValue
    }

    public description(): string {
        return (this.presets.find( p => p.value === this.value ) as EnumElement).name
    }
}