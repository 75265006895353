
import { createRouter, createWebHashHistory } from 'vue-router';
import Calculator from '../views/Calculator.vue'

const myRoutes = [
    {
        path: '/',
        name: 'calculator',
        component: Calculator
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue')
    },
    {
        path: '/calc/:hashcode',
        name: 'calcProduct',
        component: Calculator
    },
    {
        path: '/order/:orderid/calc/:hashcode',
        name: 'calcOrderProduct',
        component: Calculator
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: myRoutes
})

export default router
