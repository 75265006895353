
import { computed, defineComponent, ref } from "vue";
import modal from "./Modal.vue";
import ErrorModal from "./ErrorModal.vue";
import Login from "./user/Login.vue";
import Register from "./user/Register.vue";

export default defineComponent({
    components: { modal, ErrorModal, Login, Register },

    setup() {
        const isLoginFormOpen = ref(false);
        const tabs = ["Вход", "Регистрация"];
        const currentTab = ref("Вход");
        const currentTabComponent = computed(() =>
            currentTab.value === "Вход" ? "Login" : "Register"
        );
        const switchLoginForm = () =>
            (isLoginFormOpen.value = !isLoginFormOpen.value);

        return {
            isLoginFormOpen,
            tabs,
            currentTab,
            currentTabComponent,
            switchLoginForm,
        };
    },
});
