
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import Product from "@/model/Product";

export default defineComponent({
    setup() {
        const store = useStore()
        let activeProduct = computed( () => store.state.currentProduct as Product )

        return {
            store,
            activeProduct
        }
    },

    methods: {
        addProduct(): void {
            if (typeof(this.activeProduct) === 'undefined')
                throw new Error("product or products list are undefined!")

                this.store.dispatch('session/addProduct', this.activeProduct)
        },

        unitPrice(): string {
          return "not implemented!";
        },

        discountPrice(): string {
            return "not implemented!";
        }
    }
})

