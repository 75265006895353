import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: "javascript://",
    value: _ctx.element.value,
    class: _normalizeClass(_ctx.isActive),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enumUnit.setValue(_ctx.element.value)))
  }, _toDisplayString(_ctx.element.name), 11, _hoisted_1))
}