import EnumElement from './elements/EnumElement';
import AbstractProductUnit from '../AbstractProductUnit';
import SwitchValue from './types/SwitchValue';

export default class Delivery extends AbstractProductUnit<number> implements SwitchValue {
    public readonly uiUnitType: string = 'CheckBoxUnit';
    public readonly enumType: string = 'form-check';
    public readonly presets: EnumElement[];

    public constructor(id: string, defaultValue: number, presets?: EnumElement[]) {
        super(id, 'Доставка по Архангельску');
        this.value = defaultValue;
        this.presets = (presets != null)
            ? presets
            : [ new EnumElement(0, '', ''), new EnumElement(1, '', '') ];
    }

    public setValue(newValue: number): void {
        this.value = newValue
    }

    public isChecked(): boolean {
        return this.value == this.presets[1].value;
    }

    public switchValue(): void {
        this.value = this.value^1;
    }

    public description(): string {
        return (this.value) ? this.title : "";
    }
}