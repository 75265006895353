import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5c9475c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "col-label" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "col-select form-element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown = _resolveComponent("dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.unit.id
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        innerHTML: _ctx.unit.title
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_dropdown, {
        options: _ctx.unit.presets,
        selected: _ctx.selected,
        onUpdateOption: _ctx.updateSelectedProduct
      }, null, 8, ["options", "selected", "onUpdateOption"])
    ])
  ], 8, _hoisted_1))
}