import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!

  return (_ctx.showError())
    ? (_openBlock(), _createBlock(_component_modal, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("h3", null, "Ошибка: " + _toDisplayString(_ctx.error), 1),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearError()))
            }, "Ok")
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}