import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cffe45a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cartList" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "rect" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products.entries(), (p) => {
          return (_openBlock(), _createElementBlock("li", {
            key: p[0]
          }, [
            _createVNode(_component_router_link, {
              to: '/calc/' + p[0]
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(p[1].title) + ":", 1),
                _createElementVNode("p", {
                  innerHTML: p[1].getDescription()
                }, null, 8, _hoisted_2)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", {
        href: "javascript://",
        class: "active",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.storeOrder()))
      }, " Отправить заказ ")
    ], 512), [
      [_vShow, _ctx.products.size > 0 && _ctx.isUserLoggedIn]
    ])
  ]))
}