
import { computed, defineComponent, PropType } from 'vue';

import EnumElement from '@/model/unit/elements/EnumElement';
import AbstractProductUnit from '@/model/AbstractProductUnit';

const EnumValuesSize = defineComponent({
  props: {
    element: {
      type: Object as PropType<EnumElement>,
      required: true
    },
    enumUnit: {
      type: Object as PropType<AbstractProductUnit<number>>,
      required: true
    }
  },

  setup(props) {
    const isActive = computed(() => props.enumUnit.getValue() == props.element.value ? 'active' : '')
    return { isActive }
  }
})

export default EnumValuesSize
