import Order from '@/model/Order'
import { Session } from '@/model/auth/Session'
import axiosWrapper from './AxiosWrapper'

/**
 * Запрос списка заказов. 
 * TODO: для получения списка требуется активная сессия 
 *       из которой будем брать id пользователя
 * @returns 
 */
export async function list(): Promise<Order[]> {
    const response = await axiosWrapper.withSession().get(process.env.VUE_APP_ORDERS_URL_PATH)
    return createOrdersList(response.data)
}

export async function storeOrder(session: Session, order: Order): Promise<number> {
    const orderId = axiosWrapper.withSession().put(process.env.VUE_APP_PUT_ORDER_URL_PATH, {
        order: order
    }).then(response => {
        return response.data.id as number
    })

    return  orderId
}

function createOrdersList(ordersList: any[]): Order[] {
    return ordersList.map(o => new Order(o._id, o.created, o.clientId, o.items))
}