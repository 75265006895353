import { BasicClient } from "./BasicClient";

export type Session = {
    sessionId: string
    user: BasicClient
}

export default class SessionImpl implements Session 
{
    public constructor(readonly sessionId: string, readonly user: BasicClient) {
        // Empty cdefault constructor
    }
}